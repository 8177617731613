<template>
  <div v-if="custDetails !== null" class="mt-4">
    <div :class="canBasicPermission ? '' : 'mb-4'"
      class="card top_primary_card  bg-primary font-bold text-white p-2 rounded-xl  w-full relative"
    >
      <div class="flex justify-center items-center">
        <h1 class="heading-1 text-center">{{ custDetails.companyName }}</h1>
        <div v-if="custDetails.rating > 0">
          <div style="position: relative;" class=" heading-1 text-3xl`">
              <i  :class="`fa-solid fa-star text-warning `"></i>
              <span class=" heading-7   absolute top-0.5 left-0.5 right-0 items-center p-2   flex justify-center "> <span class="pr-1">{{custDetails.rating}}</span> </span>
          </div>
        </div>
      </div>
      <div class=" text-error heading-4 absolute right-12 mr-1 top-2 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="isDelConfirm = true">
        <i class="fa-solid fa-trash"></i>
      </div>
      <div class=" text-primary heading-4 absolute right-2 top-2 cursor-pointer bg-white px-2 py-1 rounded-xl" @click="updateCustomer()">
        <i class="fa-solid fa-pen-to-square"></i>
      </div>
      <!-- <div @click="updateCustomer()" class=" absolute right-0 top-0  text-white hover:text-white h-10 w-10 flex items-end cursor-pointer">
        <span class=" heading-3 "><i class="fa-solid fa-pen-to-square"></i></span>
      </div> -->
    </div>
    <div class="py-2" v-if="canBasicPermission">
      <div class="card bg-white rounded-xl p-4 my-2" v-if="custDetails.hotNotes !== ''">
        <div class="text-gray3 font-bold">Hot Note </div>
        <div class="text-text1 heading-5">{{custDetails.hotNotes}}</div>
      </div>
    </div>
    <div>
         <div class="lg:grid grid-cols-3 gap-4">
            <div v-if="canBasicPermission">
                <div class="card bg-white rounded-xl p-4 mb-4">
                    <p class=" text-gray3 font-bold mb-4">Contacts</p>
                    <div v-for="(item, index) in custDetails.contacts" :key="index">
                        <div class="flex justify-between items-center mb-4">
                            <div>
                                <span class="text-gray2"><i class="fa-solid fa-user w-8"></i>:</span>
                                <span class="mb-4 font-bold text-text1"> {{item.firstName}} {{item.lastName}} <span class="heading-7 text-gray4" v-if="item.isPrimary">(Primary)</span></span>
                            </div>
                            <span @click="updateContact(item)" class="pl-2 cursor-pointer text-primary"><i class="fa-solid fa-pen-to-square"></i></span>
                        </div>
                        <div class="mb-4" v-if="item.emailAddress !== ''">
                            <span class="text-gray2"><i class="fa-solid fa-envelope w-8"></i>:</span>
                            <span class="mb-4  text-text1"> {{item.emailAddress}}</span>
                        </div>
                        <div class="mb-4 flex whitespace-nowrap">
                            <span class="text-gray2"><i class="fa-solid fa-phone w-8"></i>:</span>
                            <p class=" flex flex-wrap  text-text1">
                                <span class="pl-1">
                                    <span v-if="item.phoneNumber !== ''" class="text-gray2">P</span> {{item.phoneNumber| phone}} 
                                    <span class="text-gray2" v-if="item.otherNumber !== ''">,</span>
                                </span>
                                <span v-if="item.otherNumber !== ''" class="pl-1">
                                    <span class="text-gray2"> O</span> {{item.otherNumber| phone}}
                                </span>
                                <span class="text-gray2" v-if="item.otherNumber === '' && item.phoneNumber === ''" >-</span>
                            </p>
                        </div>
                        <div class="divider my-4"  v-if="index < custDetails.contacts.length - 1"></div>
                    </div>
                    <p class=" text-right ">
                       <span @click="addnewContact" class="text-primary cursor-pointer heading-6">+ Add New Contact</span></p>
                </div>
                <div class="card bg-white rounded-xl p-4 mb-4">
                    <div class="flex justify-between items-center">
                        <p class=" text-gray3 font-bold">Address</p>
                        <span @click="addNewqAddress" class="pl-2 cursor-pointer text-primary"><i class="fa-solid fa-plus"></i></span>
                    </div>
                    <div class="mt-4" v-if="custDetails.addressList !== null">
                      <div v-for="(item, index) in custDetails.addressList" :key="index + 'd'" class="mb-4">
                        <div class="text-gray4 heading-6" v-if="item.addressTitle !== ''">{{ item.addressTitle }}:</div>
                        <div class="flex whitespace-nowrap justify-between">
                          <div class="flex items-start">
                            <div>
                              <span @click="openMap(item.latitude, item.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2">:</span></span>
                            </div>
                            <div class="pl-1 relative">
                                <p class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap cursor-pointer" @click="item.isOpen = !item.isOpen">
                                {{item.fullAddress}}
                                </p>
                                <div v-if="item.isOpen">
                                  <p  class="text-text1 heading-6">
                                    <span v-if="item.houseNo !== ''">#: {{item.houseNo}}</span>
                                    <span v-if="item.houseNo !== ''"> (Floor: {{item.floorNumber}})</span>
                                  </p>
                                  <p v-if="item.buildingName!== ''" class="text-text1 ">Bldg: {{item.buildingName}}</p>
                                  <p v-if="item.directionNote!== ''" class="text-text1 whitespace-pre-wrap">Directions: {{item.directionNote}}</p>
                                  <div class="p-1">
                                    <span @click="updateAddress(item)" class="text-primary cursor-pointer heading-5"><i class="fa-solid fa-pen-to-square"></i></span>
                                    <span @click="deleteAddressConfirmation(item.customerAddressId)" class="text-error cursor-pointer heading-5 pl-3"><i class="fa-regular fa-trash-can"></i></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="flex items-start">
                              <span @click="item.isOpen = !item.isOpen" v-if="item.isOpen" class="text-primary cursor-pointer"><i class="fas fa-chevron-up"></i></span>
                              <p @click="item.isOpen = !item.isOpen" v-if="!item.isOpen" class="text-primary cursor-pointer"><i class="fas fa-chevron-down"></i></p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" col-span-2 mb-10" v-if="tabTitle.length > 0">
                <div class="card bg-white rounded-xl p-4 mb-4">
                    <div>
                       <div class="mb-4">
                            <TabCompo :tabsList="tabTitle" />
                       </div>
                       <div v-if="selectedName === 'Jobs'">
                        <!-- <Jobs /> -->
                        <JobList :customerId="parseInt(this.$route.params.customerId)" />
                       </div>
                       <div v-if="selectedName === 'Invoice'">
                        <InvoiceList />
                       </div>
                       <div v-if="selectedName === 'Payment'">
                        <PayementList />
                       </div>
                       <div v-if="selectedName === 'Quote'">
                        <QuoteList />
                       </div>
                       <div v-if="selectedName === 'Tasks'">
                        <TaskList :customerId="parseInt(this.$route.params.customerId)"/>
                       </div>
                       <div v-if="selectedName === 'Request'">
                        <ReqList :customerId="parseInt(this.$route.params.customerId)"/>
                       </div>
                    </div>
                </div>
                <div class="card bg-white rounded-xl p-4">
                    <div>
                       <div class="mb-4">
                            <CustomerActivity :DetailObj="{detailId: custDetails.customerId}" />
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="delteAddressConfirm" class="popup_overlay relative px-4">
       <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
          <div  class="mb-5 font-bold">
            <p class="heading-3 text-text1">Are you sure to delete this address?</p>
          </div>
          <div class="flex gap-2 justify-end">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="delteAddressConfirm = false"/>
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Delete'" @buttonAction="deleteAddress"/>
          </div>
      </div>
    </div>
    <div v-if="isDelConfirm" class="popup_overlay relative px-4">
       <div style="width: 660px;" class="custom_dialog rounded-xl h-min p-4">
          <div  class="mb-5 font-semibold">
            <p class="heading-3 text-text1">Delete Customer: <span class="text-primary">{{this.custDetails.companyName}}</span></p>
            <p class="heading-5 mt-3 text-text1">Are you sure you want to delete this customer?</p>
            <div class="flex items-center border border-error rounded-lg p-2 mt-3 heading-6">
              <i class="fa-solid fa-triangle-exclamation  text-error heading-3"></i>
              <p class=" text-error pl-3">Deleting this customer will delete all job, invoice, payment, contact, address, Task</p>
            </div>
          </div>
          <div class="flex gap-2 justify-end">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="isDelConfirm = false"/>
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Delete now'" @buttonAction="deleteApi"/>
          </div>
      </div>
    </div>
    <AddContact v-if="updatesContact" :detailObj="custDetails" :updateContDetail="contactDetailForUpdate" />
    <AddressAdd v-if="addAddress" :updateContDetail="contactDetailForUpdate" />
    <UpdateCompanyInfo v-if="updateCompanyName" :dialogTtile="'Update Customer'" :updateCustData="custDetails" />
    <EditAddress :showAdditional="false" :jobDetail="seletedAddress" v-if="editJobAddress" />
  </div>
</template>
<script>
import EditAddress from '@/View/components/EditAddress.vue'
import MyJobApp from "@/api/MyJobApp.js";
import Button from '@/View/components/globalButton.vue'
import TabCompo from '@/View/components/tabs.vue'
import JobList from '@/View/job/components/JobList.vue'
import InvoiceList from '@/View/customer/components/InvoiceList.vue'
import PayementList from '@/View/customer/components/PayementList.vue'
import QuoteList from '@/View/customer/components/quoteList.vue'
import ReqList from '@/View/customer/components/requestList.vue'
import TaskList from '@/View/customer/components/TaskList.vue'
import UpdateCompanyInfo from '@/View/customer/components/UpdateCompanyInfo.vue'
import AddContact from '@/View/customer/components/AddContact.vue'
// import AddressAdd from '../components/AddressAdd.vue'
import AddressAdd from '@/View/components/AddressAdd.vue'
import {FilterPermissions} from '@/utils/Permissions.js'
import CustomerActivity from './components/CustomerActivity.vue';

export default {
  name: "customer",
  components: {
    UpdateCompanyInfo,
    Button,
    EditAddress,
    TabCompo,
    JobList,
    QuoteList,
    AddressAdd,
    PayementList,
    TaskList,
    ReqList,
    InvoiceList,
    AddContact,
    CustomerActivity,
  },
  data() {
    return {
      selectedName: 'Jobs',
      canBasicPermission: FilterPermissions('customer_basic'),
      seletedAddress: {},
      editJobAddress: false,
      isDelConfirm: false,
      activeIndex: 0,
      contactDetailForUpdate: null,
      deleteSelectId: null,
      updatesContact: false,
      delteAddressConfirm: false,
      updateCompanyName: false,
      addAddress: false,
      custDetails: null,
      tabTitle: [],
      tabs: [
        {name: 'Jobs', active: true, canViewPermission: FilterPermissions('job')},
        {name: 'Invoice', active: false , canViewPermission: FilterPermissions('invoice')},
        {name: 'Payment', active: false , canViewPermission: true},
        {name: 'Quote', active: false , canViewPermission: FilterPermissions('quote')},
        {name: 'Tasks', active: false , canViewPermission: FilterPermissions('task')},
        {name: 'Request', active: false, canViewPermission: FilterPermissions('request')}
      ]
    };
  },
  created() {},

  mounted() {
    for (let index = 0; index < this.tabs.length; index++) {
      if (this.tabs[index].canViewPermission) {
        this.tabTitle.push(this.tabs[index])
      }
    }
    if (this.tabTitle.length > 0) {
      this.tabTitle[0].active = true
      this.selectedName = this.tabTitle[0].name
    }
    if (parseInt(this.$route.query.activeTab) >= 0) {
      this.tabsSet(parseInt(this.$route.query.activeTab))
      setTimeout(() => {
        this.$router.replace({'query': null});
      }, 500);
    }
    this.$root.$on('EditAddressHandler', (response, data) => {
      if (response && data !== null && this.editJobAddress) {
        this.UpdateCustAddress(data)
      }
      this.editJobAddress = false
    })
    this.getCustDetails();
    this.$root.$on('addAddressHandler', (response, data) => {
      if (response, data !== null) {
        this.saveCustomerAddress(data)
      }
        this.addAddress = false
    })
    this.$root.$on('updateCustHandler', (response) => {
      this.updateCompanyName = false
      if (response) {
        this.getCustDetails()
      }
    })
    this.$root.$on('setNewTabActive', (index) => {
        this.tabsSet(index)
    })
    this.$root.$on("addContactHandler", (response) => {
      this.updatesContact = false
      this.contactDetailForUpdate = null
      console.log(response);
      if (response) {
        this.getCustDetails()
      }
    });
  },
  beforeDestroy() {
    this.$root.$off("setNewTabActive");
    this.$root.$off("addContactHandler");
    this.$root.$off("updateCustHandler");
    this.$root.$off("addAddressHandler");
    this.$root.$off("EditAddressHandler");
  },
  methods: {
    UpdateCustAddress (data) {
      let Object = {
        addressTitle: data.addressTitle,
        customerAddressId: this.seletedAddress.customerAddressId,
        customerId: this.seletedAddress.customerId,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        country: data.country,
        latitude: data.latitude,
        longitude: data.longitude,
        fullAddress: data.fullAddress,
        houseNo: data.houseNo,
        floorNumber: data.floorNumber,
        buildingName: data.buildingName,
        directionNote: data.directionNote,
        selectedRadius: 0,
        contactNumber: "",
        contactName: "",
      }
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
       MyJobApp.addCustomerAddress(
        Object,
        response => {
          this.getCustDetails()
          this.seletedAddress = {}
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    saveCustomerAddress (data) {
      data.customerId = parseInt(this.$route.params.customerId)
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
       MyJobApp.addCustomerAddress(
         data,
          response => {
            this.getCustDetails()
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      
    },
    updateCustomer () {
      this.updateCompanyName = true
    },
    updateAddress (item) {
      this.seletedAddress = item
      this.editJobAddress = true
    },
    deleteAddressConfirmation (addressId) {
      this.deleteSelectId = addressId 
     this.delteAddressConfirm = true
    },
    deleteAddress () {
     MyJobApp.deleteCustomerAddress(
        this.deleteSelectId,
        parseInt(this.$route.params.customerId),
        (response) => {
          this.deleteSelectId = null
          this.delteAddressConfirm = false
          this.getCustDetails()
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    updateContact (detail) {
        this.updatesContact = true
        this.contactDetailForUpdate = detail
    },
    addnewContact () {
        this.updatesContact = true
    },
    addNewqAddress () {
      this.addAddress = true
    },
    tabsSet (index) {
      this.tabTitle.forEach((element, i) => {
          if (index === i) {
              element.active = true
              this.selectedName = element.name
              this.activeIndex = i
          } else {
              element.active = false
          }
      });
    },
    deleteApi () {
      MyJobApp.RemoveLeadCustomer(
      parseInt(this.$route.params.customerId),
      'customer',
        response => {
          console.log('response', response);
          document.body.style = 'overflow: visible;'
          this.$router.push({name: 'customer'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getCustDetails() {
      console.log("call", this.$route);
      this.$store.dispatch("SetLoader", {
        status: true,
        message: "Loading...",
      });
      MyJobApp.getCustomerDetail(
        parseInt(this.$route.params.customerId),
        (response) => {
          let custDetails = response.Data;
          let addInfo = custDetails.addressList === null ? [] : custDetails.addressList
          for (let index = 0; index < addInfo.length; index++) {
              addInfo[index].isOpen = false
            }
            this.custDetails = custDetails
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    openMap (lat, lng) {
     let url = 'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng
      window.open(url, '_blank')
    }
  }
};
</script>
<style scoped>
</style>